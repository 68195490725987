import React from "react";
import Apple from "./icons/Apple";
import logo_white from "./images/logo_white.png";
import app_screens_light from "./images/app_screens_light.png";
import app_screens_dark from "./images/app_screens_dark.png";
import "./App.css";

function App() {
  return (
    <main>
      <div className="intro">
        <a
          href="https://queue.community"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo_white} alt="Queue logo" height="48" width="48" />
        </a>
        <h1>
          Hello! Queue is an iPhone app, and you can download it on the App
          Store.
        </h1>
      </div>

      <img
        className="product-image light-mode"
        src={app_screens_light}
        alt="Queue product screens"
        width="100%"
      />
      <img
        className="product-image dark-mode"
        src={app_screens_dark}
        alt="Queue product screens"
        width="100%"
      />

      <div className="action">
        <a
          href="https://apps.apple.com/us/app/queue-personal-crm/id1451429218?itsct=apps_box&amp;itscg=30200"
          className="button"
        >
          {<Apple />}
          <span>Download on the App Store</span>
        </a>
      </div>
    </main>
  );
}

export default App;
